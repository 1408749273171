// 懒加载 等待
import React from 'react';
import { Spin, Icon } from 'antd';
import styles from './index.module.less';

function Loading() {
  const antIcon = <Icon type="loading" className="fz24" spin />;

  return (
    <div className={styles['container']}>
      <main className={styles['main']}>
        <Spin indicator={antIcon} />
      </main>
    </div>
  );
}

export default Loading;
