import React, { Suspense, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import Loading from '../components/Loading';

const Login = lazy(() => import('../pages/login'));
const Main = lazy(() => import('../pages/main'));

function RouterConfig() {
  return (
    <Suspense fallback={<Loading/>}>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/" component={Main} />
      </Switch>
    </Suspense>
  );
}

export default RouterConfig;
