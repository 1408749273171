import React, {Component} from 'react';
import {ConfigProvider} from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import {HashRouter as Router} from 'react-router-dom';
import ErrorBoundary from '../components/ErrorBoundary';
import RouteConfig from './router';

class APP extends Component {
    render() {
        return (
            <ConfigProvider locale={zh_CN}>
                <ErrorBoundary>
                    <Router>
                        <RouteConfig/>
                    </Router>
                </ErrorBoundary>
            </ConfigProvider>
        );
    }
}

export default APP;
