// 组件错误边界处理
import React, { Component } from 'react';
import styles from './index.module.less';
import errorImg from '../../assets/images/500.png';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  static getDerivedStateFromError(error) {
    // 更新 state 使下一次渲染能够显示降级后的 UI
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // 错误上传服务器
    console.log(`错误日志：${error} ${info}`);
  }

  render() {
    const { hasError } = this.state;

    if (hasError) {
      return (
        <div className={styles['container']}>
          <main className={styles['main']}>
            <a href="/" className={styles['btn-login']}>
              重新登录
            </a>
            <img src={errorImg} alt="500" className={styles['error-img']} />
          </main>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
